import React, { useEffect, useRef, useState, useContext } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import gsap from "gsap";
import text, { buttonAlt } from "styles/text";
import media from "styles/media";
import { navigate } from "gatsby-link";

import { ReactComponent as BlackBallSVG } from "svg/speakerBall1.svg";
import { ReactComponent as GradientBallSVG } from "svg/speakerBall3.svg";
import { ReactComponent as BottomLineSVG } from "svg/speakerLine.svg";
import { ReactComponent as BottomLineTSVG } from "svg/speakerLineT.svg";
import { ReactComponent as DREArrowSVG } from "svg/dreArrow.svg";
import { MobileContext } from "components/layout";
import { TabletContext } from "components/layout";
import { TabletPortraitContext } from "components/layout";
import { DesktopContext } from "components/layout";
import { FullWidthContext } from "components/layout";

type Props = {
  allSpeakers: any;
  hideNode?: boolean;
};

const Speakers = ({ allSpeakers, hideNode }: Props): JSX.Element => {
  const row1 = useRef<HTMLDivElement>(null);
  const row2 = useRef<HTMLDivElement>(null);
  const row3 = useRef<HTMLDivElement>(null);
  const inner = useRef<HTMLDivElement>(null);
  const [expandedHeight, setExpandedHeight] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const mobile = useContext(MobileContext);
  const tablet = useContext(TabletContext);
  const tabletPortrait = useContext(TabletPortraitContext);
  const desktop = useContext(DesktopContext);
  const fullWidth = useContext(FullWidthContext);

  useEffect(() => {
    if (row1.current) {
      const tl = gsap.timeline({ repeat: -1 });
      tl.to(".row__1", {
        x: "-100%",
        ease: "none",
        duration: 20,
      }).to(".row__1", { x: 0, duration: 0 });
    }
    if (row2.current) {
      const tl = gsap.timeline({ repeat: -1 });
      tl.to(".row__2", {
        x: "-100%",
        ease: "none",
        duration: 80,
      }).to(".row__2", { x: 0, duration: 0 });
    }
    if (row3.current) {
      const tl = gsap.timeline({ repeat: -1 });
      tl.to(".row__3", {
        x: "-100%",
        ease: "none",
        duration: 20,
      }).to(".row__3", { x: 0, duration: 0 });
    }
  }, []);

  const speakerCards = allSpeakers
    .sort((a: any, b: any) => {
      return a.order - b.order;
    })
    .map((speaker: any, i: any) => {
      const SpeakerSocials = speaker.socials.map((link: any, i: number) => {
        let social;

        if (link.name.includes("facebook")) {
          social = "Facebook";
        } else if (link.name.includes("instagram")) {
          social = "Instagram";
        } else if (link.name.includes("linkedin")) {
          social = "LinkedIn";
        } else if (link.name.includes("twitter")) {
          social = "Twitter";
        }

        return (
          link.name && (
            <a
              key={`link-${i}`}
              href={link.name}
              target="_blank"
              rel="noopener noreferrer"
              title={`${speaker.name} on ${social}`}
            >
              <img src={link.img} alt="" />
            </a>
          )
        );
      });
      const slug = speaker.name.split(" ").join("-").toLowerCase();

      const check = SpeakerSocials.length > 0;

      const speakerNames = speaker.name.split(" ");

      return (
        <Speaker
          key={`speaker-${speaker.name}-${i}`}
          onClick={() => navigate(`/${slug}`)}
        >
          <HeadShot src={speaker.image.url} />
          <Name>
            {speakerNames[0]} <br /> {speakerNames[1]}{" "}
            {speakerNames[2] && speakerNames[2]}
          </Name>

          <Job>{speaker.jobTitle}</Job>
          <Company>{speaker.company}</Company>
          {check && <Socials>{SpeakerSocials}</Socials>}
          <ViewBio>
            <span>View Bio</span> <DREArrow />
          </ViewBio>
        </Speaker>
      );
    });

  useEffect(() => {
    if (inner.current) {
      setExpandedHeight(inner.current.getBoundingClientRect().height);
      if (speakerCards.length < 9) {
        setInitialHeight(inner.current.getBoundingClientRect().height);
      }
    }
  }, [desktop, fullWidth, mobile, speakerCards.length, tablet, tabletPortrait]);

  return (
    <Wrapper id="speakers">
      <Overline>Past Speakers</Overline>
      <Title>
        See who presented at
        <br />
        DRE Con 2021
      </Title>
      <SpeakersContainer
        expandedHeight={expandedHeight}
        initialHeight={allSpeakers.length < 9 && initialHeight}
        isOpen={isOpen}
      >
        <Inner ref={inner}>{speakerCards}</Inner>
      </SpeakersContainer>
      {speakerCards.length > 8 && (
        <ViewSpeakers onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? `View All Speakers` : "View Less"}
        </ViewSpeakers>
      )}
      <BottomLine />
      <BottomLineT />
      <BlackBall />
      <Gradient1 />
      {!hideNode && <Gradient2 />}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 2.38vw 0 0 0;
  overflow: hidden;
  position: relative;
  ${media.tablet} {
    padding: 0 0 0 0;
  }
  ${media.mobile} {
    padding: 43.2vw 4vw 40.6vw;
    overflow: visible;
  }
`;
const Title = styled.h3`
  ${text.fullWidth.H3};
  margin-bottom: 2.38vw;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  ${media.tablet} {
    ${text.tablet.H3};
    width: 64.51vw;
    margin-bottom: 3.6vw;
  }
  ${media.mobile} {
    ${text.mobile.H3}
    margin-bottom: 15.2vw;
  }
`;

const Overline = styled.p`
  ${text.fullWidth.Overline};
  margin-bottom: 1.19vw;
  position: relative;
  z-index: 1;
  text-align: center;
  ${media.tablet} {
    ${text.tablet.Overline};

    margin-bottom: 2.4vw;
  }
  ${media.mobile} {
    ${text.mobile.Overline}
    margin-bottom: 5.3vw;
  }
`;
const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${media.mobile} {
    justify-content: space-between;
  }
  ${media.fullWidth} {
  }
`;

const SpeakersContainer = styled.div<{
  expandedHeight: number;
  initialHeight: number | false;
  isOpen: boolean;
}>`
  position: relative;
  z-index: 1;
  margin: 7.86vw 0 0.6vw 20.89vw;
  width: 62vw;
  height: ${(props) =>
    props.isOpen
      ? `${props.expandedHeight}px`
      : props.initialHeight
      ? props.initialHeight
      : "61.43vw"};
  overflow: hidden;
  transition: height 0.5s;
  ${media.tablet} {
    margin: 11.99vw 0 7.19vw 11.99vw;
    width: unset;
    height: ${(props) =>
      props.isOpen
        ? `${props.expandedHeight}px`
        : props.initialHeight
        ? props.initialHeight
        : "330vw"};
  }
  ${media.mobile} {
    margin: 0;
    width: unset;
    height: ${(props) =>
      props.isOpen
        ? `${props.expandedHeight}px`
        : props.initialHeight
        ? props.initialHeight
        : "390vw"};
  }
`;

const ViewSpeakers = styled.button`
  position: relative;
  display: block;
  margin: 0 auto 2.38vw;
  ${buttonAlt};
  ${media.tablet} {
    border: 1px solid black;

    color: black;
    margin-bottom: 6vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const BottomLine = styled(BottomLineSVG)`
  margin-left: 18.63vw;
  margin-bottom: -4px; //compensate for extra svg space
  width: 31.34vw;
  height: 13.18vw;
  position: relative;
  display: inline-block;
  ${media.tablet} {
    display: none;
  }
  ${media.mobile} {
    display: none;
  }
`;

const BottomLineT = styled(BottomLineTSVG)`
  display: none;

  ${media.tablet} {
    display: inline-block;
    width: 29.26vw;
    height: 21.46vw;
    margin-left: 20.74vw;
  }
  ${media.mobile} {
  }
`;

const BlackBall = styled(BlackBallSVG)`
  position: absolute;
  width: 7.86vw;
  height: 7.86vw;
  left: 11.13vw;
  top: 0.42vw;
  z-index: 0;

  ${media.tablet} {
    top: 0;
    left: 6.83vw;
  }
  ${media.mobile} {
    width: 20.8vw;
    height: 20.8vw;
    left: -10vw;
  }
`;

const Gradient1 = styled(GradientBallSVG)`
  position: absolute;
  width: 15.71vw;
  height: 15.71vw;
  left: 88.39vw;
  top: 5vw;

  ${media.tablet} {
    width: 18.35vw;
    height: 18.35vw;
    top: 4vw;
  }
  ${media.mobile} {
    width: 28vw;
    height: 28vw;
    left: 80vw;
  }
`;
const Gradient2 = styled(Gradient1)`
  position: absolute;
  width: 15.89vw;
  height: 15.89vw;
  right: -4.44vw;
  left: auto;
  top: auto;
  bottom: 0;
  transform: rotate(120deg);
  ${media.tablet} {
    width: 18.59vw;
    height: 18.59vw;
    bottom: 11.63vw;
  }
  ${media.mobile} {
    width: 41.3vw;
    height: 41.3vw;
    bottom: -20vw;
    right: -10vw;
  }
`;

const Name = styled.p`
  ${text.fullWidth.H5};
  font-size: 1.43vw;
  font-weight: 400;
  margin-bottom: 0.6vw;

  line-height: 100%;

  ${media.tablet} {
    ${text.tablet.H5};
    font-size: 5.04vw;
    height: 10.31vw;
    margin-top: 7.19vw;
    margin-bottom: 2.4vw;
  }
  ${media.mobile} {
    ${text.mobile.H5}

    height: 11.7vw;
    margin-bottom: 2.7vw;
  }
  ${media.fullWidth} {
  }
`;

const ViewBio = styled.a`
  ${text.fullWidth.H6};
  font-size: 1.19vw;
  color: ${colors.drePurple};
  display: flex;
  align-items: center;
  margin-top: 0.48vw;
  ${media.tablet} {
    margin-top: 0.6vw;
    ${text.tablet.H6};
    font-size: 2.4vw;
    font-weight: 300;
  }
  ${media.mobile} {
    order: 2;
    ${text.mobile.H6}
    font-weight: 300;
  }
`;

const HeadShot = styled.img`
  width: 12.8vw;
  height: 12.8vw;
  margin-bottom: 2.5vw;

  ${media.tablet} {
    width: 33.33vw;
    height: 33.33vw;
    margin-bottom: 0;
  }
  ${media.mobile} {
    width: 40vw;
    height: 40vw;
    margin-bottom: 8vw;
  }
`;
const DREArrow = styled(DREArrowSVG)`
  width: 0.89vw;
  height: 0.95vw;
  margin-left: 0.6vw;
  transition: 0.4s;
  ${media.tablet} {
    width: 1.8vw;
    height: 1.92vw;
    margin-left: 1.2vw;
  }
  ${media.mobile} {
    width: 4vw;
    height: auto;
    margin-left: 2.7vw;
  }
  ${media.fullWidth} {
  }
`;

const Job = styled.p`
  ${text.fullWidth.BodyS};
  color: black;
  font-weight: 300;
  font-size: 0.95vw;

  transition: 0.4s;
  height: 2vw;
  line-height: 120%;
  margin-bottom: 0.6vw;
  ${media.tablet} {
    ${text.tablet.BodyS}
    line-height: 100%;
    font-size: 2.88vw;
    height: 6vw;
    opacity: 1;
    color: black;
    position: initial;
    margin-bottom: 2.4vw;
  }
  ${media.mobile} {
    ${text.mobile.BodyS}
    line-height: 100%;
    opacity: 1;
    color: black;
    position: initial;
    margin-bottom: 2.7vw;
    height: 6vw;
  }
`;

const Company = styled.p`
  ${text.fullWidth.BodyS};
  color: black;
  font-size: 0.95vw;
  position: relative;
  margin-bottom: 0.6vw;
  ${media.tablet} {
    ${text.tablet.BodyS}
    line-height: 100%;
    font-weight: 500;
    font-size: 2.88vw;
    position: initial;
    opacity: 1;
    color: black;
    margin-bottom: 5.3vw;
  }
  ${media.mobile} {
    ${text.mobile.BodyS}
    line-height: 100%;
    font-weight: 500;
    position: initial;
    opacity: 1;
    color: black;
    margin-bottom: 5.3vw;
  }
`;

const Socials = styled.div`
  padding: 0.6vw 0.6vw 0 0.6vw;
  background: ${colors.drePurple};
  position: absolute;
  top: 11.7vw;
  left: 0;

  display: flex;
  flex-direction: column;
  transition: 0.4s;
  img {
    width: 1.31vw;
    height: 1.31vw;
    margin-bottom: 0.6vw;
  }
  a {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;
  }

  ${media.tablet} {
    padding: 0.96vw 0.96vw 0.96vw 0;
    opacity: 1;
    flex-direction: row;
    top: 30.17vw;
    left: 0;
    width: fit-content;
    a {
      display: inline-flex;
    }
    img {
      width: 3.48vw;
      height: 3.48vw;
      margin-left: 0.96vw;
      margin-bottom: 0;
    }
  }
  ${media.mobile} {
    padding: 0.8vw 0.8vw 0.8vw 0;
    opacity: 1;
    flex-direction: row;
    top: 36vw;
    right: initial;
    left: 0;
    a {
      display: inline-flex;
    }
    img {
      width: 4vw;
      height: 4vw;
      margin-left: 0.8vw;
    }
  }
`;

const Speaker = styled.div`
  width: 12.8vw;
  height: auto;
  position: relative;
  margin-right: 2.38vw;
  margin-bottom: 6.85vw;
  z-index: 0;
  cursor: pointer;
  ${media.hover} {
    :hover {
      ${DREArrow} {
        transform: translateX(0.36vw);
      }
    }
  }
  ${media.mobile} {
    width: 40vw;

    margin-right: 0;
    margin-bottom: 13.3vw;
    display: flex;
    flex-direction: column;
  }
  ${media.tablet} {
    width: 33.33vw;
    height: 72.3vw;
    margin-right: 5.88vw;
    margin-bottom: 11.75vw;
  }
`;

export default Speakers;
