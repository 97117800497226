import React, { useContext } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import media, { tablet } from "styles/media";
import text, { buttonAlt, buttonStyle } from "styles/text";
import { ReactComponent as BallsTSVG } from "svg/heroBallsTablet.svg";
import { TabletContext } from "components/layout";
import { ReactComponent as BallsSVG } from "./images/balls.svg";
import photoImage from "./images/photo@2x.png";

type Props = {
  date: string;
  watchUrl: string;
  year: string;
};

const Hero = ({ date, watchUrl, year }: Props): JSX.Element => {
  const tablet = useContext(TabletContext);

  return (
    <Wrapper>
      <Speaker src={photoImage} alt="" />
      {tablet ? <BlackBall1T /> : <BlackBall1 />}
      <TopRow>
        <Overline>
          {date}, {year}
        </Overline>{" "}
        <Line />
        <Overline>VIRTUAL EVENT</Overline>
      </TopRow>
      <Title>Data Reliability Engineering Conference {year}</Title>
      <Row>
        <WatchNowLink href={watchUrl} target="_blank" rel="noopener noreferrer">
          Watch Now
        </WatchNowLink>
        <SpeakersLink href="#speakers">Speakers</SpeakersLink>
      </Row>
    </Wrapper>
  );
};

const BlackBall1T = styled(BallsTSVG)`
  ${media.tablet} {
    width: 100vw;
    height: 97vw;
    left: 0vw;
    top: 6.24vw;
    position: absolute;
    z-index: 0;
    transition: 0.5s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  }
`;

const Speaker = styled.img`
  width: 25.95vw;
  height: 39.76vw;
  position: absolute;
  top: 4.52vw;
  right: 12.86vw;
  z-index: 2;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  ${media.tablet} {
    width: 31.06vw;
    height: 47.6vw;
    right: 18.94vw;
    top: 55.52vw;
  }
  ${media.mobile} {
    width: 56.3vw;
    height: 92vw;
    top: 136vw;
    right: 31.7vw;
    z-index: 2;
  }
`;

const BlackBall1 = styled(BallsSVG)`
  width: 100%;
  height: 89.64vw;
  left: 0;
  top: 2.2vw;
  position: absolute;
  z-index: 0;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  ${media.tablet} {
  }
  ${media.mobile} {
    width: 200%;
    height: 170vw;
    top: 135vw;
    left: -102vw;
    rect[x="1284"] {
      transform: translate(30vw, -25vw);
    }
    rect[x="862"] {
      transform: translate(5vw, 55vw);
    }
  }
`;

const Wrapper = styled.section`
  margin-bottom: -51vw;
  padding: 15.77vw 0 56.07vw 5.95vw;
  overflow: hidden;
  position: relative;
  transition: padding 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  ${Speaker} {
    top: 7.79vw;
  }
  ${BlackBall1} {
    top: 5.77vw;
  }
  ${media.tablet} {
    padding: 16.19vw 0 100.6vw 11.99vw;

    ${BlackBall1T} {
      top: 14vw;
    }

    ${Speaker} {
      top: 63vw;
    }
  }
  @media (max-width: ${tablet}px) {
    margin-bottom: -24vw;
  }
  ${media.mobile} {
    padding: 44.53vw 4vw 16vw;

    ${Speaker} {
      top: 148vw;
    }
    ${BlackBall1} {
      top: 147vw;
    }
  }
`;

const Title = styled.h1`
  ${text.fullWidth.H1};
  font-weight: 700;
  width: 46.19vw;
  margin-bottom: 2.38vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.H1};
    width: 62.95vw;
    margin-bottom: 3.6vw;
  }
  ${media.mobile} {
    ${text.mobile.H1}
    font-weight: 700;
    width: 100%;
    line-height: 100%;
    margin-bottom: 8vw;
  }
`;

const Overline = styled.p`
  ${text.fullWidth.Overline};
  margin-bottom: 1.19vw;
  position: relative;
  z-index: 1;
  ${media.tablet} {
    ${text.tablet.Overline}
    margin-bottom: 2.4vw;
  }
  ${media.mobile} {
    ${text.mobile.Overline}
    margin-bottom: 5.9vw;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  ${media.tablet} {
  }
  ${media.mobile} {
    flex-direction: column;
    a {
      width: 100%;
    }
  }
  ${media.fullWidth} {
  }
`;

const WatchNowLink = styled.a`
  ${buttonStyle};
  margin-right: 1.79vw;
  ${media.mobile} {
    margin-bottom: 5.3vw;
    margin-right: 0;
  }
`;

const SpeakersLink = styled.a`
  ${buttonAlt};
  margin-right: 1.79vw;
  ${media.tablet} {
    color: black;
    border: 1px solid black;
  }
  ${media.mobile} {
    margin-bottom: 335.7vw;
  }
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.19vw;
  p {
    margin: 0;
  }
  ${media.tablet} {
  }
  ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: -8vw;
    margin-bottom: 8vw;
    ${Overline}:nth-child(1) {
      margin: 0;
    }
  }
`;

const Line = styled.div`
  height: 1.07vw;
  width: 0.15vw;
  margin-right: 1.19vw;
  margin-left: 1.19vw;
  background: ${colors.drePurple};
  ${media.tablet} {
    height: 2.6vw;
    width: 0.25vw;
    margin: 0 2.4vw;
  }
  ${media.mobile} {
    width: 14.67vw;
    height: 0.53vw;
    margin: 4vw 0;
  }
`;

export default Hero;
